@import url('https://fonts.googleapis.com/css2?family=Gagalin&display=swap');
*{
  fontFamily: 'Gagalin, sans-serif' 

}
.survey-container {
    min-height: 100vh;
    background-color: black;
    color: white; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .survey-content {
    max-width: 48rem;
    width: 100%;
    text-align: center;
  }
  
  .logo-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .logo {
    height: 2.5rem;
    width: auto;
  }
  
  .survey-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .survey-title {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .input-label {
    font-size: 1.125rem;
    letter-spacing: 0.025em;
  }
  
  .key-input {
    max-width: 24rem;
    margin: 0 auto;
    padding: 0.5rem;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 0.25rem;
    text-align: center;
  }
  
  .submit-button {
    min-width: 200px;
    width: auto;
    padding: 0.75rem 1.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 0 auto;
    display: block;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #e5e5e5;
  }
  
  .submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .response-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: #1a1a1a;
  }
  
  .success {
    color: #4ade80;
  }
  
  .error {
    color: #f87171;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  @media (max-width: 640px) {
    .survey-title {
      font-size: 1.5rem;
    }
    .input-label {
      font-size: 1rem;
    }
  }